import * as React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Sponsors from '../components/sponsors/sponsors';
import Testimonials from '../components/testimonials/testimonials';
import { useStaticQuery, graphql } from "gatsby"

const Stories = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulContactPage {
      emailAddress {
        link
        label
      }
      phone {
        link
        label
      }
    }
  }
`)
  const email = data.contentfulContactPage.emailAddress;
  const phoneNumber = data.contentfulContactPage.phone;
  return (
    <Layout>
      <SEO title="Stories" />
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-side col-lg-9 col-md-12 col-sm-12">
              <div className="service-detail">
                <div className="inner-box">
                  <div className="lower-content">
                    <Testimonials />
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar-side col-lg-3 col-md-12 col-sm-12">
              <aside className="sidebar sticky-top">
                <div className="sidebar-widget enquiry-widget">
                  <div className="widget-content">
                    <h4>For Enquiries</h4>
                    <div className="text">You can also send us an email and we’ll get in touch shortly.</div>
                    <div className="number"><a href={phoneNumber.url}>{phoneNumber.label}</a></div>
                    <a href={email.url} className="theme-btn btn-style-two"><span className="txt">Send Email</span></a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <Sponsors />
    </Layout>
  );
};
export default Stories;
