import * as React from 'react';

import Slider from 'react-slick';
import { useStaticQuery, graphql } from "gatsby"

export default function Sponsors() {
  const data = useStaticQuery(graphql`
  {
    contentfulStoryPage {
      sponsorLogos {
        partnerLogos {
          file {
            url
          }
          title
        }
      }
    }
  }
`)
  const logos = data.contentfulStoryPage.sponsorLogos[0].partnerLogos;
  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
  };

  return (
    <div className="sponsors-section">
      <div className="auto-container">
        <div className="carousel-outer">
          <ul>
            <Slider {...settings}>
              {logos.map(({ file, title }, index) => (
                <li key={index}>
                  <div className="image-box">
                    <img src={file.url} alt={title} />
                  </div>
                </li>
              ))}
            </Slider>
          </ul>
        </div>
      </div>
    </div>
  );
};
