import * as React from 'react';

import Slider from 'react-slick';
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default function Testimonials() {
  const data = useStaticQuery(graphql`
  {
    contentfulStoryPage {
      name
      stories {
        author
        title
        link
        linkName
        text {
          raw
        }
      }
    }
  }
`)
  const story = data.contentfulStoryPage.stories;
  const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
  };

  return (
    <div className="testimonial-section" style={{ paddingTop: 0 }}>
      <div className="auto-container">
        <div className="sec-title">
          <div className="title">Tekki Fii</div>
          <h2>The <span>Success</span> Stories</h2>
        </div>
        <Slider {...settings}>
          {story.map(({ author, title, text, link, linkName }, index) => (
            <div className="testimonial-block">
              <div className="inner-box" key={index}>
                <div className="quote icofont-quote-right" />
                <div className="author">{title} <span>{author}</span></div>
                <div className="text">
                  {text && renderRichText(text)}
                </div>
                <br />
                <br />
                <a href={link} target="_blank" rel="noreferrer">{linkName}</a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
